<template>
  <div class="order-history-page">
    <DarkBg class="dark-bg-component"></DarkBg>
    <DialogChooseEmployee v-model:show="CHOOSE_EMPLOYEE"></DialogChooseEmployee>
    <Swiper :orders="swiperOrders" v-if="width < 992" class="swiper-modal" :class="{ active: modalToggler }" v-touch:press="touchHoldHandler" @cancelModal="closeSwiperModal" @cancelModalInst="closeSwiperModalInst" :key="swiperKey"></Swiper>
    <Dialog class="dish-remove-dialog" v-if="dialogExport" @submitDialog="submitOrderExport" v-model:show="dialogExport" :showCancelBtn="false" :firstBtnText="ORDERS_HISTORY_TRANSLATE?.modals?.export?.form?.btn?.submit">
      <template v-slot:title>{{ ORDERS_HISTORY_TRANSLATE?.modals?.export?.title }}</template>
      <span class="message m-text-des-n">{{ ORDERS_HISTORY_TRANSLATE?.modals?.export?.description }}</span>
      <div class="inputs-box">
        <InputSelect v-if="GET_EXPORT_PERIODS?.from" :options="GET_EXPORT_PERIODS?.from" @selectPressed="setDateStart" :label="ORDERS_HISTORY_TRANSLATE?.modals?.export?.form?.from"></InputSelect>
        <InputSelect v-if="GET_EXPORT_PERIODS?.to" :options="GET_EXPORT_PERIODS?.to" @selectPressed="setDateEnd" :label="ORDERS_HISTORY_TRANSLATE?.modals?.export?.form?.to"></InputSelect>
      </div>
      <template v-slot:checkbox-confirm>
        <div class="confirm-box">
          <label class="check-confirm">
            <input type="checkbox" @click="datesPeriods.flag = !datesPeriods.flag" />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.0643 18L20.4016 6M8.97718 17.9996L3.60156 12.2635" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </label>
          <span class="blue-text s-text-alert">{{ ORDERS_HISTORY_TRANSLATE?.modals?.export?.form?.checkbox }}</span>
        </div>
      </template>
    </Dialog>
    <div class="main">
      <div class="orders-header">
        <h2 class="r-title-1">
          {{ ORDERS_HISTORY_TRANSLATE?.common?.title }}&nbsp;
          <span v-if="intervalFlag == 2" class="interval-toggler" :key="togglerKey">
            <span @click="toggleDown"> за {{ activeInterval }} </span>
            <div class="buttons">
              <div @click="toggleDown">
                <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 5L4.5 1L1 5" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div @click="toggleUp">
                <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </span>
        </h2>
        <div v-if="userRole == 2" class="orders-header-buttons">
          <InfoBtn @click="stepsReinit">
            <div class="guest-info">
              <div class="guest-info-inner">
                {{ ORDERS_HISTORY_TRANSLATE?.tooltips?.info }}
              </div>
            </div>
          </InfoBtn>
          <SearchDish :placeholder="ORDERS_HISTORY_TRANSLATE?.common?.btn?.search" @inpEvent="searchFunc" @searchClear="clearSearchFunc" v-model:value="search"></SearchDish>
          <div class="order-add-wrapper">
            <ButtonExport class="mb-10" @click="openExportDialog">{{ ORDERS_HISTORY_TRANSLATE?.common?.btn?.export }}</ButtonExport>
            <InfoMessage v-if="ordersCount > 0 && userRole == 2" right="25%" top="126%" arrowDir="tr" :class="{ show: infoStep == 1 }">
              <div class="steps-box">
                <span class="step-text m-text-steps-title">{{ ORDERS_HISTORY_TRANSLATE?.tips?.first }}</span>
                <div class="step-interactive-box">
                  <span class="active"></span>
                  <span></span>
                  <div @click="nextInfoStep" class="step-btn s-text-alert">
                    {{ ORDERS_HISTORY_TRANSLATE?.tips?.btn?.apply }}
                  </div>
                </div>
              </div>
            </InfoMessage>
          </div>
        </div>
      </div>
      <div class="orders-list">
        <template v-if="!emptyTextFlag && intervalFlag == 2">
          <div class="orders-list-header" :class="{ sticky: stickyFlag }">
            <div v-if="userRole == 2" class="orders-list-title title-name">
              <span class="hovered" @click="sortByCount">
                {{ ORDERS_HISTORY_TRANSLATE?.common?.table?.employee }}
                <svg v-if="sortFlag == 1" :class="{ rotated: sortCount == 1 }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </div>
            <div class="orders-list-title title-date">
              <span class="hovered" @click="sortByDate">
                {{ ORDERS_HISTORY_TRANSLATE?.common?.table?.date }}
                <svg v-if="sortFlag == 2" :class="{ rotated: sortDate == -1 }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </div>
            <div class="orders-list-title title-order" :class="{ user: userRole == 1 }">
              <span class="hovered" @click="sortByDish">
                <template v-if="userRole == 2">
                  {{ ORDERS_HISTORY_TRANSLATE?.common?.table?.orders }}
                </template>
                <template v-else>
                  {{ ORDERS_HISTORY_TRANSLATE?.common?.table?.user_orders }}
                </template>
                <svg v-if="sortFlag == 3" :class="{ rotated: sortDish == 1 }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </div>
            <div class="orders-list-title title-sum">
              <span class="hovered" @click="sortByTotal">
                {{ ORDERS_HISTORY_TRANSLATE?.common?.table?.sum }}
                <svg v-if="sortFlag == 4" :class="{ rotated: sortTotal == 1 }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </div>
          </div>
          <div class="orders-list-body" :key="orderBodyKey">
            <div v-for="(orderItem, index) in dataOrders" :key="index" class="orders-list-item-wrapper">
              <div class="orders-list-item" :class="accordionClasses(orderItem)">
                <div class="orders-list-item-inner" :class="userRole == 1 ? 'user' : ''" @click="modalOrder(orderItem, index)">
                  <div v-if="userRole == 2" class="order-name">{{ employeeCount(orderItem?.items) }} {{ plural(orderItem?.items) }}</div>
                  <div class="order-date">
                    {{ dateFormat(orderItem?.date) }}&nbsp;<span class="gray">({{ dayName(orderItem?.date) }})</span>
                  </div>
                  <div class="order-order" :class="{ user: userRole == 1 }">
                    <template v-if="userRole == 2"> {{ dayQty(orderItem) }} {{ pluralDish(orderItem) }} </template>
                    <template v-else>
                      <div class="detail-item" v-for="(item, index) in orderItem?.items" :key="index">
                        <div class="order-order" :class="{ user: userRole == 1 }">
                          <div v-for="(dish, index) in item?.products" :key="index">
                            <img :src="dish?.image?.url" :alt="dish?.image?.title" />
                            <div class="row-content">
                              <div class="row-text">
                                <span class="gray">{{ dish?.text?.price }} ₴&nbsp;</span>
                                <span class="order-text">{{ dish?.text?.main }}&nbsp;</span>
                                <span v-if="dish?.text?.surcharge" class="blue-text">{{ dish?.text?.surcharge?.text }} {{ dish?.text?.surcharge?.price }} ₴</span>
                              </div>
                              <p v-if="dish?.text?.modifiers" class="modifier">{{ dish?.text?.modifiers }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="order-sum">
                    {{ dayTotal(orderItem) }} ₴
                    <span v-if="userRole == 2" class="order-collapse">
                      <svg :class="{ rotated: orderItem?.show }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L4.5 5L8 1" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div v-if="width > 991 && userRole == 2" class="orders-details" :id="`details-${index}`">
                  <div class="orders-details-inner">
                    <div class="detail-item" v-for="(item, index) in orderItem?.items" :key="index">
                      <div class="order-name">{{ item?.name }}</div>
                      <div class="order-date"></div>
                      <div class="order-order">
                        <div v-for="(dish, index) in item?.products" :key="index" class="order-content">
                          <img :src="dish?.image?.url" :alt="dish?.image?.title" />
                          <div class="row-content">
                            <div class="row-text">
                              <span class="gray">{{ dish?.text?.price }} ₴&nbsp;</span>
                              <span class="order-text">{{ dish?.text?.main }}&nbsp;</span>
                              <span v-if="dish?.text?.surcharge" class="blue-text">{{ dish?.text?.surcharge?.text }} {{ dish?.text?.surcharge?.price }} ₴</span>
                            </div>
                            <p v-if="dish?.text?.modifiers" class="modifier">{{ dish?.text?.modifiers }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="order-sum" :class="{ 'blue-text': item?.isOverLimit }">{{ item?.total }} ₴</div>
                    </div>
                  </div>
                </div>
              </div>
              <InfoMessage v-if="index == 0" right="-11px" top="65px" arrowDir="tr" :class="{ show: infoStep == 2 }">
                <div class="steps-box">
                  <span class="step-text m-text-steps-title">{{ ORDERS_HISTORY_TRANSLATE?.tips?.second }}.</span>
                  <div class="step-interactive-box">
                    <span></span>
                    <span class="active"></span>
                    <div @click="nextInfoStep" class="step-btn s-text-alert">{{ ORDERS_HISTORY_TRANSLATE?.tips?.btn?.apply }}</div>
                  </div>
                </div>
              </InfoMessage>
            </div>
          </div>
        </template>
        <div v-else-if="intervalFlag == 1 || emptyTextFlag" class="orders-list-body">
          <div class="orders-list-item">
            <div class="orders-list-item-inner">
              <div class="text-empty">
                <template v-if="intervalFlag == 1">
                  {{ ORDERS_HISTORY_TRANSLATE?.common?.table?.empty_orders }}
                </template>
                <template v-else>
                  {{ ORDERS_HISTORY_TRANSLATE?.search?.not_found?.title }}&nbsp; <span class="gray">{{ ORDERS_HISTORY_TRANSLATE?.search?.not_found?.description }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a v-if="pageYOffset > topOffset" v-smooth-scroll="{ duration: 700, updateHistory: false }" href="#navOrders" class="btn-to-top">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10L12 3M19 10L12 3M12 3L12 21" stroke="#000E29" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>
    <Footer class="footer-wrapper"></Footer>
  </div>
</template>

<script>
import DarkBg from "@/components/dark-bg.vue";
import DialogChooseEmployee from "@/components/dialogs/choose-employee.vue";
import SearchDish from "@/components/parts/search-dish";
import ButtonExport from "@/components/parts/button-export.vue";
import Swiper from "@/components/collapse/swiper.vue";
import InfoMessage from "@/components/parts/info-message";
import InfoBtn from "@/components/parts/info-btn";
import Dialog from "@/components/dialogs/dialog-submit-setting.vue";
import InputSelect from "../components/parts/input-select";
import Footer from "@/components/footer-setting.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DarkBg,
    DialogChooseEmployee,
    SearchDish,
    ButtonExport,
    InfoMessage,
    InfoBtn,
    Swiper,
    Dialog,
    InputSelect,
    Footer
  },
  data() {
    return {
      intervalFlag: 0,
      stickyFlag: false,
      emptyTextFlag: false,
      swiperOrders: {
        count: "",
        date: "",
        day: "",
        items: "",
        total: ""
      },
      search: "",
      orderBodyKey: 1,
      dataOrders: null,
      dataInterval: null,
      datesPeriods: {
        from: "",
        to: "",
        flag: false
      },
      toggleFlag: true,
      exportFlag: true,
      activeInterval: null,
      sortFlag: 2,
      sortDate: 1,
      sortCount: 1,
      sortDish: 1,
      sortTotal: 1,
      width: 0,
      topOffset: 0,
      pageYOffset: 0,
      infoStep: 0,
      modalToggler: false,
      swiperKey: 1,
      dialogExport: false,
      togglerKey: false,
      swiperPos: 0
    };
  },
  computed: {
    ...mapGetters(["ORDERS", "CHOOSE_EMPLOYEE", "GET_EXPORT_DATA", "GET_EXPORT_ID", "GET_ORDER_LIST", "GET_PERIODS", "GET_EXPORT_PERIODS", "GET_MY_ROLE", "ORDERS_HISTORY_TRANSLATE"]),
    userRole() {
      return this.GET_MY_ROLE;
    },
    ordersCount() {
      if (this.GET_ORDER_LIST) {
        return this.GET_ORDER_LIST.length;
      } else {
        return 0;
      }
    }
  },
  methods: {
    ...mapActions(["GetOrders", "EmployeesList", "SetOrderList", "SetPeriods", "SetExportPeriods", "SetExportOrder", "GetExportId", "GetExportStatus", "OrdersHistoryTranslate"]),
    updateWidth() {
      this.width = window.innerWidth;
    },
    async userModal() {
      document.querySelector("body").style.overflow = "hidden";
      this.$store.commit("Set_Choose_Employee", true);
      await this.EmployeesList();
    },
    offsetTop() {
      if (document.querySelector(".orders-list")) {
        this.topOffset = document.querySelector(".orders-list").offsetTop;
      }
    },
    scrollBtnShow() {
      this.pageYOffset = window.pageYOffset;
    },
    nextInfoStep() {
      if (this.infoStep <= 2) this.infoStep++;
      if (this.infoStep == 3) {
        localStorage.setItem("step-orders", true);
        document.querySelector(".dark-bg-component").style.display = "none";
      }
    },
    stepsReinit() {
      this.infoStep = 1;
      localStorage.setItem("step-orders", false);
      if (this.userRole == 2) document.querySelector(".dark-bg-component").style.display = "block";
    },
    async submitOrderExport() {
      if (this.exportFlag) {
        this.exportFlag = !this.exportFlag;

        let temp = {};
        if (this.datesPeriods.from) temp.from = this.datesPeriods.from;
        if (this.datesPeriods.to) temp.to = this.datesPeriods.to;
        temp.flag = this.datesPeriods.flag ? 1 : 0;

        if (!Object.keys(temp).length == 0) {
          await this.GetExportId(temp);
          let interval = setInterval(() => {
            if (!this.GET_EXPORT_DATA || this.GET_EXPORT_DATA == "passed") {
              clearInterval(interval);
              this.SetExportOrder(temp);
            } else {
              this.GetExportStatus();
            }
          }, 2000);
          this.exportFlag = !this.exportFlag;
          this.dialogExport = false;
          this.datesPeriods = {
            from: "",
            to: "",
            flag: false
          };
          document.querySelector("body").style.overflow = null;
        }
      }
    },
    setDateStart(item) {
      this.datesPeriods.from = item.value;
    },
    setDateEnd(item) {
      this.datesPeriods.to = item.value;
    },
    sortByCount() {
      this.sortFlag = 1;
      let data = this.dataOrders;
      this.sortCount = this.sortCount * -1;
      data.sort((a, b) => (a.items.length > b.items.length ? 1 : -1) * this.sortCount);

      this.dataOrders = data;
    },
    sortByDate() {
      this.sortFlag = 2;
      let data = this.dataOrders;
      this.sortDate = this.sortDate * -1;
      data.sort((a, b) => (Date.parse(a.date) < Date.parse(b.date) ? 1 : -1) * this.sortDate);

      this.dataOrders = data;
    },
    sortByDish() {
      this.sortFlag = 3;
      let data = this.dataOrders;
      this.sortDish = this.sortDish * -1;
      data.sort((a, b) => (a.qty > b.qty ? 1 : -1) * this.sortDish);

      this.dataOrders = data;
    },
    sortByTotal() {
      this.sortFlag = 4;
      let data = this.dataOrders;
      this.sortTotal = this.sortTotal * -1;
      data.sort((a, b) => (a.total > b.total ? 1 : -1) * this.sortTotal);

      this.dataOrders = data;
    },
    employeeCount(item) {
      return item?.length;
    },
    plural(employee) {
      if (employee) {
        let plur = employee.length % 10 == 1 && employee.length % 100 != 11 ? 0 : employee.length % 10 >= 2 && employee.length % 10 <= 4 && (employee.length % 100 < 10 || employee.length % 100 >= 20) ? 1 : 2;
        return plur == 0 ? this.ORDERS_HISTORY_TRANSLATE?.plural?.employee[1] : plur == 1 ? this.ORDERS_HISTORY_TRANSLATE?.plural?.employee[2] : this.ORDERS_HISTORY_TRANSLATE?.plural?.employee[3];
      }
    },
    dateFormat(date) {
      let temp = new Date(date);
      let year = temp.getFullYear();
      let month = temp.getMonth() + 1 > 9 ? temp.getMonth() + 1 : `0${temp.getMonth() + 1}`;
      let day = temp.getDate() + 1 > 9 ? temp.getDate() : `0${temp.getDate()}`;
      let newDate = `${day}.${month}.${year}`;

      return newDate;
    },
    dayName(date) {
      let temp = new Date(date);
      let dayName = temp.toLocaleString("ru", { weekday: "short" });

      return dayName;
    },
    dayQty(data) {
      if (this.search) {
        let counter = 0;
        data.items.forEach(item => {
          item.products.forEach(subItem => {
            counter += subItem.qty;
          });
        });
        return counter;
      } else {
        return data.qty;
      }
    },
    openExportDialog() {
      document.querySelector("body").style.overflow = "hidden";
      this.dialogExport = true;
    },
    pluralDish(dish) {
      if (dish) {
        let plur = dish.qty % 10 == 1 && dish.qty % 100 != 11 ? 0 : dish.qty % 10 >= 2 && dish.qty % 10 <= 4 && (dish.qty % 100 < 10 || dish.qty % 100 >= 20) ? 1 : 2;
        return plur == 0 ? this.ORDERS_HISTORY_TRANSLATE?.plural?.dish[1] : plur == 1 ? this.ORDERS_HISTORY_TRANSLATE?.plural?.dish[2] : this.ORDERS_HISTORY_TRANSLATE?.plural?.dish[3];
      }
    },
    dayTotal(data) {
      if (this.search) {
        let counter = 0;
        data.items.forEach(item => {
          counter += item.total;
        });
        return counter;
      } else {
        return data.total;
      }
    },
    searchFunc() {
      if (!this.search) {
        this.dataOrders = this.GET_ORDER_LIST;
        this.dataOrders.forEach(item => {
          item.show = false;
        });
        this.emptyTextFlag = false;
      } else {
        let oldArr = this.GET_ORDER_LIST;
        let newArr = [];
        let subStr = this.search.toLowerCase();
        let count = 0;
        oldArr.forEach(item => {
          let newArr2 = [];
          let count2 = 0;
          let flag = false;
          item.items.forEach(subItem => {
            if (subItem.name.toLowerCase().includes(subStr)) {
              newArr2[count2] = subItem;
              count2++;
              flag = true;
            }
          });
          if (flag) {
            newArr[count] = {
              date: item.date,
              qty: item.qty,
              total: item.total,
              items: newArr2,
              show: false
            };
            count++;
          }
        });
        this.dataOrders = newArr;

        this.dataOrders.length > 0 ? (this.emptyTextFlag = false) : (this.emptyTextFlag = true);
      }
      this.orderBodyKey *= -1;
    },
    clearSearchFunc() {
      this.sortFlag = 2;
      this.search = "";
      this.emptyTextFlag = false;
      this.dataOrders = this.GET_ORDER_LIST;
      this.dataOrders.forEach(item => {
        item.show = false;
      });
      this.orderBodyKey *= -1;
    },
    async toggleUp() {
      if (this.toggleFlag) {
        this.toggleFlag = !this.toggleFlag;

        let index = null;
        this.dataInterval.forEach(function(item, i) {
          if (item.active) index = i;
        });
        this.dataInterval[index].active = false;
        index != 0 ? (this.dataInterval[index - 1].active = true) : (this.dataInterval[this.dataInterval.length - 1].active = true);
        this.togglerKey = !this.togglerKey;
        this.activeInterval = this.GET_PERIODS.filter(item => item.active)[0].text;

        await this.SetOrderList({
          period: this.GET_PERIODS.filter(item => item.active)[0].value
        });

        this.toggleFlag = !this.toggleFlag;
      }
    },
    async toggleDown() {
      if (this.toggleFlag) {
        this.toggleFlag = !this.toggleFlag;

        let index = null;

        this.dataInterval.forEach(function(item, i) {
          if (item.active) index = i;
        });
        this.dataInterval[index].active = false;
        index != this.dataInterval.length - 1 ? (this.dataInterval[index + 1].active = true) : (this.dataInterval[0].active = true);
        this.togglerKey = !this.togglerKey;
        this.activeInterval = this.GET_PERIODS.filter(item => item.active)[0].text;

        await this.SetOrderList({
          period: this.GET_PERIODS.filter(item => item.active)[0].value
        });

        this.toggleFlag = !this.toggleFlag;
      }
    },
    modalOrder(item, index) {
      if (this.userRole == 2) {
        if (this.width < 992) {
          this.modalToggler = true;
          this.swiperKey = -this.swiperKey;
          document.querySelector("body").classList.add("overflowed");
          let newData = {
            count: this.employeeCount(item.items),
            date: this.dateFormat(item.date),
            day: this.dayName(item.date),
            items: item.items,
            total: item.total
          };
          this.swiperOrders = newData;
        } else {
          if (this.width > 991) {
            item.show = !item.show;
            const elem = document.getElementById(`details-${index}`);
            const child = elem.querySelector(".orders-details-inner");
            if (item.show) {
              elem.style.position = "absolute";
              elem.style.display = "block";
              const height = child.offsetHeight;
              elem.style.position = null;
              elem.style.visibility = null;
              elem.style.height = height + "px";
            } else {
              elem.style.height = null;
            }
          }
        }
      }
    },
    closeSwiperModalInst() {
      document.querySelector(".swiper-body").style.overflow = "hidden";
      this.modalToggler = false;
      document.querySelector("body").classList.remove("overflowed");
    },
    closeSwiperModal() {
      if (this.swiperPos >= 130) {
        document.querySelector(".swiper-body").style.overflow = "hidden";
        this.modalToggler = false;
        document.querySelector("body").classList.remove("overflowed");
      }
    },
    touchHoldHandler() {
      if (document.querySelector(".swiper-item")) {
        let pos = document.querySelector(".swiper-item").getBoundingClientRect().top;
        this.swiperPos = pos;
      }
    },
    accordionClasses(item) {
      return {
        "is-closed": !item.show,
        "is-primary": item.show
      };
    },
    preventDefault(e) {
      e.preventDefault();
    },
    stickyMenu() {
      if (document.querySelector(".orders-list-header")) {
        let offsetTop = document.querySelector(".orders-list-header").getBoundingClientRect().top;
        offsetTop < 1 ? (this.stickyFlag = true) : (this.stickyFlag = false);
      }
    }
  },
  watch: {
    GET_MY_ROLE() {
      if (!localStorage.getItem("step-orders") && this.userRole == 2) {
        document.querySelector(".dark-bg-component").style.display = "block";
        localStorage.setItem("step-orders", true);
        this.infoStep++;
      }
    },
    GET_ORDER_LIST() {
      this.dataOrders = this.GET_ORDER_LIST;
    },
    GET_PERIODS() {
      if (this.GET_PERIODS.length > 0) {
        this.dataInterval = this.GET_PERIODS;
        this.activeInterval = this.GET_PERIODS.filter(item => item.active)[0].text;
        this.intervalFlag = 2;
      } else {
        this.intervalFlag = 1;
      }
    }
  },
  async mounted() {
    if (!this.ORDERS_HISTORY_TRANSLATE) {
      this.OrdersHistoryTranslate();
    }
    if (!this.GET_PERIODS) {
      await this.SetPeriods();
    }
    if (!this.ORDERS) {
      await this.GetOrders();
    }
    if (this.GET_PERIODS.length > 0) {
      this.SetOrderList({
        period: this.GET_PERIODS[0].value
      });
      this.dataInterval = this.GET_PERIODS;
      this.activeInterval = this.GET_PERIODS.filter(item => item.active)[0].text;
      this.intervalFlag = 2;
    } else {
      this.intervalFlag = 1;
    }
    if (!this.GET_EXPORT_PERIODS) {
      this.SetExportPeriods();
    }
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
    if (navigator.userAgent.indexOf("iPhone") > 0 || navigator.userAgent.indexOf("iPad") > 0) document.querySelector("body").classList.add("ios");
    this.offsetTop();
    this.scrollBtnShow();
    this.stickyMenu();
    window.addEventListener("resize", this.offsetTop);
    window.addEventListener("scroll", this.scrollBtnShow);
    window.addEventListener("scroll", this.stickyMenu);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.offsetTop);
    window.removeEventListener("resize", this.updateWidth);
    window.removeEventListener("scroll", this.scrollBtnShow);
    window.removeEventListener("scroll", this.stickyMenu);
  }
};
</script>

<style lang="scss">
// body.ios .swiper-body {
//   padding-bottom: 110px;
// }

@media (max-width: 991px) {
  body.overflowed {
    overflow: hidden;
  }
}

.order-history-page {
  color: var(--dark-100);
  position: relative;
  background: var(--bg-main);
  min-height: 100%;

  .order-content {
    flex-wrap: nowrap !important;
  }

  .row-content {
    max-width: 60%;
    display: flex;
    flex-wrap: wrap;
  }

  .user {
    .row-content {
      max-width: none;
      width: calc(100% - 37px);
    }
    & > div {
      width: 100%;
    }

    .row-text {
      width: 100%;
    }
  }

  .row-text {
    flex-wrap: nowrap !important;
  }

  .modifier {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0.4;
  }

  .gray {
    opacity: 0.4;
    white-space: nowrap;
  }

  & > .main {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 200px;

    @media (max-width: 991px) {
      overflow-x: hidden;
    }
  }

  .message {
    color: var(--dark-40);
    font-family: "Aeonik Pro", sans-serif;
  }

  .blue-text {
    color: var(--blue-main);
    white-space: nowrap;
    @media (min-width: 992px) {
      margin: 0 6px;
    }
  }

  .dialog-btns {
    margin: 0;
    flex-direction: column-reverse;
  }

  .inputs-box {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .main {
      margin-bottom: 33px;
      max-width: 45%;

      @media (max-width: 700px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .confirm-box {
    margin-bottom: 36px;
    display: flex;
    span {
      cursor: default;
      margin-bottom: -3px;
    }
  }

  .check-confirm {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid var(--blue-main);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    overflow: hidden;
    svg {
      opacity: 0;
      width: 22px;
      height: 22px;
      color: var(--blue-main);
      transition: 0.2s;
      visibility: hidden;
    }
    & input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"]:checked ~ svg {
      visibility: visible;
      background: var(--blue-main);
      opacity: 1;

      path {
        stroke: #fff;
      }
    }
  }

  .dialog-setting {
    .grey-text {
      color: var(--dark-40);
    }
    span.blue {
      color: var(--blue-main);
    }
    span.red {
      color: var(--error);
    }
    .dialog-header h2 {
      padding-right: 10px;
    }
    .surcharge-text {
      max-width: 540px;
      padding-bottom: 20px;
    }

    .mr button {
      width: auto;
    }
  }

  h2.r-title-1 {
    padding: 0 20px;
    margin-top: 84px;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;

    @media (max-width: 991px) {
      margin-top: 45px;
      margin-bottom: 16px;
    }

    @media (max-width: 767px) {
      max-height: 81px;
    }

    @media (max-width: 340px) {
      font-size: 24px;
    }

    span {
      color: var(--blue-main);
      font-weight: 400;
      font-family: "Aeonik Pro Regular", sans-serif;
    }

    .interval-toggler {
      display: flex;

      @media (max-width: 991px) {
        width: 100%;
        height: 41px;
      }

      & > span {
        cursor: pointer;
        user-select: none;
      }
    }

    .buttons {
      margin-left: 2px;
      padding: 15px 0;
      height: 54px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 764px) {
        padding: 9px 0;
        height: 41px;
        margin-left: 2px;
      }

      div {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    &:not(:first-child) {
      margin-top: 83px;
    }

    @media (max-width: 1199px) {
      width: 100%;
    }

    @media (max-width: 1199px) and (min-width: 992px) {
      margin-bottom: 20px;
    }
  }

  .search-dish {
    width: 204px;

    &:focus-within {
      width: 300px;
    }

    @media (max-width: 991px) {
      width: 48px;
      max-width: calc(100% - 90px);
      flex-shrink: 0;

      input::placeholder {
        color: transparent;
      }

      svg {
        margin: 0;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        position: relative;
      }
    }
  }

  .step-interactive-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    span {
      height: 4px;
      width: 24px;
      border-radius: 10px;
      background: var(--white-40);
      margin-right: 8px;
      margin-bottom: 6px;
      &.active {
        background: var(--white-100);
      }
    }
  }

  .step-btn {
    background: var(--white-100);
    border-radius: 8px;
    width: auto !important;
    height: 48px;
    cursor: pointer;
    font-family: "Aeonik Pro", sans-serif;
    text-transform: uppercase;
    color: var(--blue-main);
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  .orders {
    &-header {
      margin-bottom: 35px;
      padding-right: 20px;
      width: 100%;
      display: flex;

      @media (min-width: 1301px) {
        justify-content: space-between;
        align-items: flex-end;
      }

      @media (max-width: 1300px) {
        flex-direction: column;
      }

      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      &-buttons {
        width: 100%;
        display: flex;

        @media (min-width: 1200px) {
          justify-content: flex-end;
        }

        @media (max-width: 1199px) {
          padding-left: 20px;
        }

        & > *:not(:first-child) {
          margin-left: 18px;

          @media (max-width: 767px) {
            margin-left: 11px;
          }
        }
      }
    }

    &-list {
      width: 100%;

      &-header {
        padding: 12px 40px;
        display: flex;
        position: sticky;
        top: 0;
        z-index: 2;

        @media (max-width: 991px) {
          display: none;
        }

        &.sticky {
          backdrop-filter: saturate(180%) blur(20px);
          background-color: rgba(245, 248, 250, 0.72);
        }
      }

      &-title {
        flex-shrink: 0;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 2;
        text-transform: uppercase;

        &.title-name {
          width: 24.6%;
        }

        &.title-date {
          width: 22.2%;
        }

        &.title-order {
          width: 42%;

          &.user {
            width: 66.6%;
          }
        }

        &.title-sum {
          width: 11.2%;
        }

        & > span {
          &.hovered {
            cursor: pointer;
            transition: 0.2s ease-out;

            &:hover {
              color: var(--blue-btn-hover);
            }
          }
          svg {
            position: relative;
            bottom: 1px;
            transition: 0.5s ease;

            &.rotated {
              transform: rotate(-180deg);
            }
          }
        }
      }

      &-body {
        padding: 0 20px;
      }

      &-item {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 12px;
        background: var(--white-100);
        border-radius: 10px;
        transition: margin-bottom 0.5s ease-in-out;

        &.is-primary {
          margin-bottom: 20px;
        }

        &-inner {
          padding: 24px 20px;
          &:not(.user) {
            cursor: pointer;
          }
        }

        @media (max-width: 991px) {
          &-inner {
            width: 100%;
            justify-content: space-between;
            padding: 24px 18px 24px 20px;
          }
        }

        &-wrapper {
          position: relative;
        }

        div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex-shrink: 0;
          line-height: 24px;
          letter-spacing: 0.5px;

          &.text-empty {
            flex-shrink: initial;
          }

          &.order-date span:first-letter {
            text-transform: uppercase;
          }

          @media (min-width: 992px) {
            width: 100%;

            &.order-name {
              width: 24.6%;
            }

            &.order-date {
              width: 22.2%;
            }

            &.order-order {
              width: 42%;

              &.user {
                width: 66.6%;
              }
            }

            &.order-sum {
              display: flex;
              justify-content: space-between;
              width: 11.2%;
              &.blue-text {
                margin: 0;
              }
            }
          }

          @media (max-width: 991px) {
            &.order-name {
              display: none;
            }

            &.order-order {
              margin-left: 2px;

              &.user {
                margin: 10px 0;
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
              }
            }

            &.order-sum {
              margin-left: 3px;
            }
          }
        }

        &.empty-text {
          padding: 29px 20px;
        }

        @media (max-width: 991px) {
          .info-meassage {
            flex-direction: column;

            .step-interactive-box {
              width: 100%;
            }
          }
        }
      }
    }

    &-details {
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease-in-out;

      &.is-closed {
        display: none;
      }

      .detail-item {
        padding: 23px 20px 24px;
        border-top: 1px solid #f5f8fa;
        align-items: flex-start;

        .order-order {
          flex-direction: column;

          & > div:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .order-order img {
    margin-right: 13px;
    width: 24px;
    height: 24px;
  }

  .order {
    &-icon {
      margin-right: 6px;
      position: relative;
      bottom: 1px;
      z-index: 1;
      flex-shrink: 0;
    }
    &-name {
      padding-right: 10px;
    }
    &-add-wrapper {
      position: relative;
      flex-shrink: 0;

      @media (max-width: 991px) {
        .info-meassage {
          right: auto !important;
          left: -130px !important;

          .info-arrow {
            right: 23%;
          }
        }
      }
    }
  }

  .btn-to-top {
    z-index: 2;
    transition: 0.3s;
    position: fixed;
    bottom: 66px;
    right: 20px;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-100);
    border-radius: 10px;
    box-shadow: var(--box-shadow-second);
    cursor: pointer;
    &:active {
      transform: scale(0.9);
      box-shadow: var(--box-shadow-second);
    }
  }

  .order-collapse {
    margin-left: 12px;

    @media (max-width: 991px) {
      margin-left: 10px;
    }

    svg {
      transition: 0.5s ease;

      @media (max-width: 991px) {
        transform: rotate(-90deg);
      }

      &.rotated {
        @media (min-width: 992px) {
          transform: rotate(-180deg);
        }

        @media (max-width: 991px) {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .guest {
    &-info {
      display: none;
      position: absolute;
      bottom: calc(100% + 12px);
      left: -28px;
      z-index: 1;

      &-inner {
        padding: 20px;
        position: relative;
        width: 286px;
        color: var(--bg-white);
        font-size: 14px;
        line-height: 22px;
        background: var(--blue-main);
        border-radius: 10px;

        @media (max-width: 767px) {
          display: none;
        }

        &:after {
          content: url("data:image/svg+xml,%3Csvg width='72' height='12' viewBox='0 0 72 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.2 11.1C36.4889 11.6333 35.5111 11.6333 34.8 11.1L20 0H52L37.2 11.1Z' fill='%233CAFE9'/%3E%3C/svg%3E%0A");
          display: block;
          position: absolute;
          top: calc(100% - 4px);
          left: 0;
        }
      }
    }

    .employee-icon:hover + .guest-info {
      display: block;
    }
  }

  // .orders-list-item .order-content {
  //   flex-wrap: nowrap;
  // }

  .order-text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .btn-info:hover {
    & > div {
      display: block;
      text-transform: none;

      @media (max-width: 991px) {
        left: 0;
      }

      .guest-info-inner:after {
        left: 15px;

        @media (max-width: 991px) {
          left: -13px;
        }
      }
    }
  }
}

.fade-enter-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
