<template>
  <button class="s-text-caption btn-add">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12.667H11" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.33301 6.55588L7.99967 9.33366M7.99967 9.33366L10.6663 6.55588M7.99967 9.33366V2.66699" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span> <slot></slot> </span>
  </button>
</template>

<style lang="scss" scoped>
button {
  cursor: pointer;
  padding: 0 13px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  outline: none;
  color: var(--dark-100);
  background: var(--blue-light);
  text-transform: uppercase;
  flex-shrink: 0;
  transition: 0.2s ease-out;
  svg {
    height: 17px;
    width: 17px;
    color: var(--blue-main);
    margin-bottom: 3px;
    margin-right: 3px;

    path {
      transition: 0.2s ease-out;
    }
  }
  &:hover {
    background: var(--blue-main);
    color: var(--white-100);
    svg path {
      stroke: var(--white-100);
    }
  }
}
</style>
