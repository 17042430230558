<template>
  <div class="swiper-wrapper">
    <div class="swiper-inner">
      <div class="swiper-top" @click="cancelModalInst"></div>
      <div class="swiper-body" v-touch:swipe.bottom="cancelModal">
        <div class="swiper-item">
          <div class="swiper-date">
            {{ orderList?.date }}&nbsp;<span class="gray">({{ orderList?.day }})</span>
          </div>
          <div class="swiper-name">{{ orderList?.count }} {{ plural(orderList?.count) }}</div>
        </div>
        <div v-for="(item, index) in orderList.items" :key="index" class="swiper-item">
          <div class="swiper-name">{{ item?.name }}</div>
          <div class="swiper-sum" :class="{ 'blue-text': item?.isOverLimit }">{{ item?.total }} ₴</div>
          <div class="swiper-item-inner">
            <div v-for="(dish, index) in item.products" :key="index" class="dish-cell">
              <img :src="dish?.image?.url" :alt="dish?.image?.title" />
              <div class="cell-text m-text-des">
                <span class="cell-title">{{ dish?.text?.main }}</span>
                <div class="count-price s-text-caption">
                  <span class="gray">{{ dish?.text?.price }} ₴</span>&nbsp;
                  <span v-if="dish?.text?.surcharge" class="blue-text">{{ dish?.text?.surcharge?.text }} {{ dish?.text?.surcharge?.price }} ₴</span>
                </div>
                <p v-if="dish?.text?.modifiers" class="modifier">{{ dish?.text?.modifiers }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    orders: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["ORDERS_HISTORY_TRANSLATE"]),
    orderList() {
      return this.orders;
    }
  },
  methods: {
    cancelModal() {
      this.$emit("cancelModal");
    },
    cancelModalInst() {
      this.$emit("cancelModalInst");
    },
    plural(employee) {
      let plur = employee % 10 == 1 && employee % 100 != 11 ? 0 : employee % 10 >= 2 && employee % 10 <= 4 && (employee % 100 < 10 || employee % 100 >= 20) ? 1 : 2;
      return plur == 0 ? this.ORDERS_HISTORY_TRANSLATE?.plural?.employee[1] : plur == 1 ? this.ORDERS_HISTORY_TRANSLATE?.plural?.employee[2] : this.ORDERS_HISTORY_TRANSLATE?.plural?.employee[3];
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper {
  &-wrapper {
    display: flex;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 14, 41, 0.4);
    opacity: 1;
    transition: opacity 0.5s ease, visibility 0.7s ease;
    z-index: 4;
    touch-action: none;

    &:not(.active) {
      opacity: 0;
      visibility: hidden;

      .swiper-inner {
        transform: translateY(100%);
      }
    }

    .dish-cell {
      margin-top: 12px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;

      img {
        margin-right: 12px;
        width: 56px;
        height: 56px;
      }

      .cell-text {
        width: calc(100% - 68px);
      }

      .surcharge {
        margin-left: 4px;
        color: var(--blue-main);
      }
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: 0.7s ease;
  }

  &-top {
    position: relative;
    width: 100%;
    min-height: 130px;
    height: 100%;

    &:before {
      position: absolute;
      left: 50%;
      bottom: 8px;
      transform: translateX(-50%);
      content: "";
      width: 40px;
      background: #fff;
      border: 2px solid #fff;
      border-radius: 8px;
    }
  }

  &-body {
    margin-top: auto;
    max-height: calc(100% - 130px);
    flex-shrink: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: auto;
  }

  &-item {
    margin-bottom: 4px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 72px;
    background: var(--bg-white);
    border-radius: 10px;
    letter-spacing: 0.5px;

    &-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &-name,
  &-date,
  &-sum {
    font-size: 12px;
    line-height: 24px;
  }
}
</style>
