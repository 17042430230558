<template>
  <div class="dark-bg"></div>
</template>

<style lang="scss" scoped>
.dark-bg {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.27);
  z-index: 3;
}
</style>
