<template>
  <div class="btn-info s-text-caption">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.0013" cy="8.00033" r="5.33333" stroke="#3CAFE9" stroke-width="1.4" />
      <path d="M8 7.33301V10.6663" stroke="#3CAFE9" stroke-width="1.4" />
      <path d="M8 5.33301V6.26634" stroke="#3CAFE9" stroke-width="1.4" />
    </svg>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.btn-info {
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--blue-light);
  transition: 0.2s ease-out;
  z-index: 1;

  svg {
    path, circle {
      transition: 0.2s ease-out;
    }
  }

  &:hover {
    background: var(--blue-main);

    svg {
      path, circle {
        stroke: var(--bg-white);
      }
    }
  }
}
</style>
